<template>
  <div class="mt-8">
    <div class="container space-y-6">
      <template v-if="allPosts.length > 0">
        <div class="grid sm:grid-cols-2 xl:grid-cols-4 gap-6 xl:gap-8">
          <NewsCard
            v-for="post in allPosts"
            :id="post.id"
            :cover="post.cover"
            :title="post.title"
            :excerpt="post.excerpt"
            :slug="post.slug"
          />
        </div>
      </template>
      <div
        v-if="pending"
        class="grid sm:grid-cols-2 xl:grid-cols-4 gap-6 xl:gap-8"
      >
        <div class="w-full flex flex-col gap-4" v-for="n in 8">
          <Skeleton class="w-full aspect-[16/9]" />
          <Skeleton class="h-14" />
          <Skeleton class="h-[80px] sm:h-24" />
        </div>
      </div>
      <div v-if="!limitReached && !pending" class="flex justify-center">
        <NamiButton
          :disabled="pending"
          buttonType="secondary"
          @click="offsetLevel++"
          class="w-32"
        >
          Load more
        </NamiButton>
      </div>
      <div
        class="text-center italic text-sm sm:text-base"
        v-else-if="limitReached && !pending"
      >
        We sent Luna to find more news articles but she didn't find any!
      </div>
      <div v-if="error">
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import DOMPurify from "dompurify";
import {
  getPosts,
  getSingleMediaFromUrl,
  type Post,
  setBaseUrl,
} from "~/utils/wordpress/news";

const props = defineProps<{
  id: string;
  showcase?: boolean;
}>();

const config = useRuntimeConfig();
const offsetLevel = ref(0);
const limitReached = ref(false);
const perPage = 16;

const allPosts: {
  id: number;
  cover: string;
  title: string;
  excerpt: string;
  slug: string;
}[] = reactive([]);

setBaseUrl(config.public.wpApi);

const { pending, error } = useAsyncData(
  `category-${props.id}`,
  async () => {
    const news = await getPosts({
      per_page: perPage,
      offset: offsetLevel.value * perPage,
      categories: props.id !== "latest" ? [Number(props.id)] : undefined,
    });

    if (news.length === 0 || news.length < perPage) {
      limitReached.value = true;
    }

    const theWindow = await (async () => {
      if (window) {
        return window;
      }

      return new (await import("jsdom")).JSDOM("").window;
    })();

    const { sanitize } = DOMPurify(theWindow);

    news.forEach((newsItem) => {
      newsItem.title.rendered = sanitize(newsItem.title.rendered);
      newsItem.excerpt.rendered = sanitize(newsItem.excerpt.rendered);
    });

    const newsMediaIDMap = new Map<string, Post[]>();

    for (const newsItem of news) {
      const link = newsItem._links["wp:featuredmedia"][0].href;

      if (newsMediaIDMap.get(link)) {
        newsMediaIDMap.get(link)?.push(newsItem);
      } else {
        newsMediaIDMap.set(link, [newsItem]);
      }
    }

    const covers = await Promise.all(
      Array.from(newsMediaIDMap.entries()).map(async ([link, post]) => ({
        postIds: post.map((p) => p.id),
        cover: await getSingleMediaFromUrl(link),
      })),
    );

    allPosts.push(
      ...news.map((newsItem) => ({
        id: newsItem.id,
        cover: covers.filter((cover) => cover.postIds.includes(newsItem.id))[0]
          .cover.media_details.sizes.full.source_url,
        title: newsItem.title.rendered,
        excerpt: newsItem.excerpt.rendered,
        slug: newsItem.slug,
      })),
    );
  },
  { server: false, watch: [offsetLevel, () => props.id] },
);
</script>
